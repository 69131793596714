import store from '@/store';
import { Getters as SystemGetters } from '@/store/enums/ResourcesEnums';

const roles = [
  {
    code: 'pm',
    text: 'Property Manager',
  },
  {
    code: 'principal',
    text: 'Principal',
  },
  {
    code: 'tenant',
    text: 'Tenant',
  },
  {
    code: 'admin',
    text: 'Admin',
  },
];

export const parser = {
  country: (code) => {
    if (!code) return;

    const countryLists = store.getters[SystemGetters.GET_COUNTRY];

    const country = countryLists.find((countryObj) => {
      if (countryObj.value === code) {
        return countryObj.label;
      }
    });

    if (!country) return code;

    return country.label;
  },

  state: (code) => {
    if (!code) return;

    const stateLists = store.getters[SystemGetters.GET_LIST_STATES];
    console.log('stateLists', stateLists);
    const state = stateLists.find((stateObj) => {
      if (stateObj.value === code) {
        return stateObj.label;
      }
    });

    if (!state) return code;

    return state.label;
  },

  userRole: (roleArray) => {
    if (!roleArray) return;

    const userRoles = [] as unknown as Array<string>;
    roleArray.forEach((role) => {
      roles.find((roleObj) => {
        if (roleObj.code === role) {
          userRoles.push(roleObj.text);
        }
      });
    });

    if (!userRoles.length) return roleArray.join(', ');

    return userRoles.join(', ');
  },
};

export default parser;


import { defineComponent } from 'vue';
import { convertHexToRGBA } from '@/utils/color';

export default defineComponent({
  props: {
    classAvatar: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '100',
    },
    useIcon: {
      type: Boolean,
      default: false,
    },
    withUploadButton: {
      type: Boolean,
      default: false,
    },
    imageFile: {
      type: String,
      default: '',
    },
    acronym: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: process.env.VUE_APP_TEXT_COLOR,
    },
    backgroundColor: {
      type: String,
      default: process.env.VUE_APP_BACKGROUND_COLOR,
    },
    fontSize: {
      type: String,
      default: '1em',
    },
  },
  computed: {
    borderColor() {
      return convertHexToRGBA(this.textColor, 0.3);
    },
    defaultError() {
      if (this.useIcon) {
        return 'icon';
      }

      if (!this.acronym) {
        return 'icon';
      }

      return 'acronym';
    },
    imageSrc() {
      return this.imageFile ? this.imageFile : '';
    },
  },
});

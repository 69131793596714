export const business = {
  abn: 'XX XXXX XXXX XXXX',
  acn: 'XXX XXXX XXXX',
};

export const mobile = {
  aus: 'XXXX XXX XXX',
};

export const phone = {
  aus: 'XX XXXX XXXX',
};

export const post_code = {
  code: 'XXXX',
};

export default {
  business,
  mobile,
  phone,
};

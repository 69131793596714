
import { defineComponent } from 'vue';
import Avatar from '@/components/avatars/Avatar.vue';
import UploadProfile from '@/components/modals/avatar/UploadProfileModal.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  Getters as AuthGetters,
  Actions as AuthActions,
} from '@/store/enums/AuthEnums';
import mask from '@/utils/mask';
import toasts from '@/utils/toasts';
import parser from '@/utils/parser';
import { User } from '@/models/UserModel';
import Navigator from '@/views/v2/views/layout/Navigator.vue';
import text from '@/utils/text';

export default defineComponent({
  name: 'profile',
  components: {
    Avatar,
    Navigator,
    UploadProfile,
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      actionErrors: AuthGetters.GET_AUTH_USER_ACTION_ERROR,
    }),
    profilePicture(){
      const authUser = this.authUser as User;
      return authUser?.avatar_src;
    },
    mobileMasking() {
      return mask.mobile.aus;
    },
    userRole() {
      const authUser = this.authUser as User;

      return parser.userRole(authUser.roles);
    },
    acronym() {
      const authUser = this.authUser as User;

      if (
        authUser.first_name === '' &&
        authUser.last_name === ''
      )
        return text.acronym(authUser.email);

      return text.acronym(`${authUser.first_name} ${authUser.last_name}`);
    },
    mobileNumber() {
      const authUser = this.authUser as User;
      return text.formatMobile(authUser.mobile_number)
    }
  },
  methods: {
    ...mapActions({
      updateProfile: AuthActions.UPLOAD_PROFILE_PICTURE,
      removeAvatar: AuthActions.REMOVE_PROFILE_PICTURE,
    }),
    canAccess() {
      // return this.authUser?.roles.includes('principal');
    },
    handleUpload(values) {
      if (!values || values == '') {
        return;
      }

      const accepted = ['image/png', 'image/gif', 'image/jpeg'];

      if (!accepted.includes(values.type)) {
        toasts.error({}, 'Invalid file type.');
        return false;
      }

      this.updateProfile(values)
        .then(() => {
          toasts.success('Profile Successfully Updated');
          (this.$refs as any).uploadAvatarModal.clearUploadValue();
        })
        .catch(() => {
          const { errors, message } = this.actionErrors;
          toasts.error(errors, message);
        });
    },
    async handleRemoveAvatar() {
      return toasts.confirm(
        "Are you sure you want to remove the profile picture?",
        ({ isConfirmed }) => {
          if (isConfirmed) {
            this.removeAvatar().catch(() => {
              const { errors, message } = this.actionErrors;
              toasts.error(errors, message);
            }).finally(() => {
              (this.$refs as any).uploadAvatarModal.clearUploadValue();
            });
          } else {
            return false;
          }
        }
      );
    },
    removeSpaces(value) {
      return text.removeSpaces(value);
    },
  },
});
